import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActivityQueryTypes from "src/constants/activityQueryTypes";
import { AllActivityType } from "src/hooks/queries/activities/useActivityDetail";

export interface ActivityStateType {
  selectedRows: AllActivityType[];
  enableExport: boolean;
  loading: boolean;
  activtyType?: ActivityQueryTypes;
  error: string | undefined;
  selectedExportOption: string | null;
}

const initialState: ActivityStateType = {
  selectedRows: [],
  enableExport: false,
  loading: false,
  activtyType: ActivityQueryTypes.ALL,
  error: undefined,
  selectedExportOption: null,
};

type SetActivityType = {
  activtyType: ActivityQueryTypes;
};

export const activityStateSlice = createSlice({
  name: "activityState",
  initialState,
  reducers: {
    setActivityTypeAction: (
      state,
      { payload }: PayloadAction<SetActivityType>
    ) => {
      state.activtyType = payload.activtyType;
    },
    setSelectedRowsAction: (
      state,
      { payload }: PayloadAction<{ selectedRows: AllActivityType[] }>
    ) => {
      if (payload.selectedRows.length > 0) {
        if (payload.selectedRows.length < state.selectedRows.length) {
          state.selectedExportOption = "selected";
        } else {
          state.selectedExportOption = !state.selectedExportOption
            ? "selected"
            : state.selectedExportOption;
        }
        state.selectedRows = payload.selectedRows;
        state.enableExport = true;
      } else {
        state.selectedExportOption = null;
        state.selectedRows = [];
        state.enableExport = false;
      }
    },
    setSelectedExportOptionAction: (
      state,
      { payload }: PayloadAction<{ selectedExportOption: string | null }>
    ) => {
      state.enableExport = !!payload.selectedExportOption;
      state.selectedExportOption = payload.selectedExportOption;
    },
  },
});

const selectActivityType = createSelector(
  (state: any) => {
    return state.activityState;
  },
  (activityState: ActivityStateType) => activityState.activtyType
);

const setSelectedRow = createSelector(
  (state: any) => {
    return state.activityState;
  },
  (activityState: ActivityStateType) => activityState.selectedRows
);

const enablExport = createSelector(
  (state: any) => {
    return state.activityState;
  },
  (activityState: ActivityStateType) => activityState.enableExport
);

const selectedExportOption = createSelector(
  (state: any) => {
    return state.activityState;
  },
  (activityState: ActivityStateType) => activityState.selectedExportOption
);

export const useActivityState = () => {
  const dispatch = useDispatch();
  const actions = activityStateSlice.actions;
  const {
    setActivityTypeAction,
    setSelectedRowsAction,
    setSelectedExportOptionAction,
  } = actions;

  const setActivityType = (activtyType: ActivityQueryTypes) =>
    dispatch(setActivityTypeAction({ activtyType }));

  const setSelectedExportOption = (selectedExportOption: string | null) =>
    dispatch(setSelectedExportOptionAction({ selectedExportOption }));

  const currentActivityType = useSelector(selectActivityType);
  const selectedRows = useSelector(setSelectedRow);
  const getEnableExport = useSelector(enablExport);
  const getSelectedExportOption = useSelector(selectedExportOption);

  const setSelectedRows = useCallback(
    (newSelectedRows: AllActivityType[]) => {
      if (selectedRows?.length === newSelectedRows?.length) return null;

      dispatch(setSelectedRowsAction({ selectedRows: newSelectedRows }));
    },
    [selectedRows, dispatch, setSelectedRowsAction]
  );

  return {
    actions,
    setActivityType,
    currentActivityType,
    selectedRows,
    setSelectedRows,
    getEnableExport,
    getSelectedExportOption,
    setSelectedExportOption,
  };
};
export const activityStateReducer = activityStateSlice.reducer;
