import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { StringOrNull } from "src/types";

export enum CallTypeEnum {
  YES,
  NO,
}

export type AssignedVoicemailType = {
  id: string;
  email: StringOrNull;
  name: string;
  type: string;
};

export type AddressType = {
  city: StringOrNull;
  country: StringOrNull;
  postalCode: StringOrNull;
  stateOrProvince: StringOrNull;
  street: StringOrNull;
};
export type CallHandlingSettingsType = {
  oKToGiveOutPhysicalAddress: string;
  oKToGiveOutFax: string;
  mainBusinessPhoneNumber: string;
  greeting: string;
  aiGreeting: string;
  directionsToYourOffice: string;
  assignedVoicemails: AssignedVoicemailType[];
};
export type CallForwardingRulesType = {
  rolloverCallForwardingEnabled: boolean;
  permanentCallForwardingEnabled: boolean;
  conditionalCallForwardingEnabled: boolean;
};
export const IndustryOptions = [
  "Accounting",
  "Automotive",
  "Business Services",
  "Child/Senior Care",
  "Construction",
  "Counseling/Mental Health",
  "Dental/Orthodontics",
  "E-Commerce",
  "Education",
  "Energy/Utilities",
  "Entertainment",
  "Event Planning",
  "Finance",
  "Food & Beverage",
  "Government",
  "Home Services",
  "Insurance",
  "Law/Legal Services",
  "Manufacturing",
  "Marketing/SEO",
  "Medical",
  "Miscellaneous",
  "Non Profit",
  "Other",
  "Personal Care/Wellness",
  "Pet Care/Veterinarian",
  "Real Estate",
  "Retail",
  "Technology",
  "Transportation",
  "Travel/Leisure",
] as const;
export type IndustryOptionType = typeof IndustryOptions[number];
export type AccountInformationType = {
  whatDoesYourOrganizationDo: string;
  website: string;
  timeZone: string;
  shippingAddress: AddressType;
  physicalAddressDifferentFromBilling: boolean;
  mainBusinessPhoneNumber: string;
  hoursDaysOfOperation: any;
  holidayHoursOfOperation: string;
  fax: string;
  contractStartDate: string;
  customDaysOfOperation2: string | null;
  customHolidayHoursOfOperation: string | null;
  billingAddress: AddressType;
  name: string;
  asOk: boolean;
  timezoneForLiveCalls: string;
  industry: IndustryOptionType;
};
export type AccountSettingType = {
  id: string | null;
  callHandlingSettings: CallHandlingSettingsType | null;
  callForwardingRules: CallForwardingRulesType | null;
  accountInformation: AccountInformationType | null;
};
const initialState: AccountSettingType = {
  id: null,
  callHandlingSettings: null,
  callForwardingRules: null,
  accountInformation: null,
};

// state slice and reducers
export const accountSettingSlice = createSlice({
  name: "accountSetting",
  initialState,
  reducers: {
    setAccountSettingAction: (
      state: AccountSettingType,
      { payload }: PayloadAction<AccountSettingType>
    ) => {
      state.id = payload.id;
      state.callHandlingSettings = payload.callHandlingSettings;
      state.callForwardingRules = payload.callForwardingRules;
      state.accountInformation = payload.accountInformation;
    },
    setCallHandlingSettings: (
      state: AccountSettingType,
      { payload }: PayloadAction<CallHandlingSettingsType>
    ) => {
      state.callHandlingSettings = {
        ...state.callHandlingSettings,
        ...payload,
      } as CallHandlingSettingsType;
    },
    setCallForwardingRules: (
      state: AccountSettingType,
      { payload }: PayloadAction<CallForwardingRulesType>
    ) => {
      state.callForwardingRules = payload;
    },
    setAccountInformation: (
      state: AccountSettingType,
      { payload }: PayloadAction<AccountInformationType>
    ) => {
      state.accountInformation = {
        ...state.accountInformation,
        ...payload,
      };
    },
  },
});

export const accountSettingReducer = accountSettingSlice.reducer;

// Selector
const selectAccountSetting = createSelector(
  (state: any) => {
    return state.accountSetting;
  },
  (accountSetting: AccountSettingType) => accountSetting
);

const selectCallHandlingSettings = createSelector(
  (state: any) => {
    return state.accountSetting;
  },
  (callHandlingSettings: AccountSettingType) =>
    callHandlingSettings.callHandlingSettings
);

const selectCallForwardingRules = createSelector(
  (state: any) => {
    return state.accountSetting;
  },
  (callForwardingRules: AccountSettingType) =>
    callForwardingRules.callForwardingRules
);

const selectAccountInformation = createSelector(
  (state: any) => {
    return state.accountSetting;
  },
  (accountInformation: AccountSettingType) =>
    accountInformation.accountInformation
);

export const useAccountSettingState = () => {
  const dispatch = useDispatch();
  const actions = accountSettingSlice.actions;
  const {
    setAccountSettingAction,
    setCallHandlingSettings,
    setCallForwardingRules,
    setAccountInformation,
  } = actions;

  const setAccountSetting = (accountSetting: AccountSettingType) =>
    dispatch(setAccountSettingAction(accountSetting));

  const setCallHandlingSettingsAction = (
    callHandlingSettings: CallHandlingSettingsType
  ) => dispatch(setCallHandlingSettings(callHandlingSettings));

  const setCallForwardingRulesAction = (
    callForwardingRules: CallForwardingRulesType
  ) => dispatch(setCallForwardingRules(callForwardingRules));

  const setAccountInformationAction = (
    accountInformation: AccountInformationType
  ) => dispatch(setAccountInformation(accountInformation));

  const accountSetting = useSelector(selectAccountSetting);
  const callHandlingSettings = useSelector(selectCallHandlingSettings);
  const callForwardingRules = useSelector(selectCallForwardingRules);
  const accountInformation = useSelector(selectAccountInformation);

  return {
    accountSetting,
    callHandlingSettings,
    callForwardingRules,
    accountInformation,
    setAccountSetting,
    setCallHandlingSettingsAction,
    setCallForwardingRulesAction,
    setAccountInformationAction,
  };
};
