import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Input,
  FormControl,
} from "@chakra-ui/react";
import { useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { partialRight } from "ramda";
import renderInputErr from "src/utils/renderInputErr";

export type TransferToFormValTypes = {
  transferTo: string;
};

const schema = yup.object().shape({
  transferTo: yup
    .string()
    .required("Phone number is a required field.")
    .matches(
      /^\d{10}$/,
      "Phone number must be exactly 10 digits and contain only numbers."
    ),
});

export const initialValues = {
  transferTo: "",
};

type Props = {
  isOpen: boolean;
  onClose: any;
  onSave: any;
};

const TransferToModal = ({ isOpen, onClose, onSave }: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<TransferToFormValTypes>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  useEffect(() => {
    clearErrors();
    reset();
  }, [isOpen]);

  const renderErr = partialRight(renderInputErr, [errors]);

  const onSubmit = (data: TransferToFormValTypes) => {
    onSave(data.transferTo);
    onClose();
  };

  // console.log("TRANSFER TO MODAL RENDERED");

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus={false}>
      <ModalOverlay />
      <ModalContent
        borderRadius={"10px"}
        maxW={"500px"}
        mx={{ base: "4%", csm: 0 }}
        className={
          "pendo-transfer-to-modal pendo-settings-transfertomodal-modal"
        }
      >
        <ModalHeader
          className={"modal-header"}
          fontWeight={700}
          fontSize={"16px"}
        >
          Add Phone Number
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody py={"10px"}>
          <FormControl mt={3}>
            <Text fontWeight={300} fontSize={"14px"}>
              Provide a phone number to set yourself as Available and receive
              calls
            </Text>
            <Input
              mt={3}
              type={"tel"}
              inputMode={"numeric"}
              placeholder={"Transfer To"}
              _placeholder={{ color: "#A5B5E3" }}
              {...register("transferTo", {
                setValueAs: (v) => v.trim(),
              })}
              isInvalid={!!errors?.transferTo}
              className={
                "pendo-transfer-to-modal-transfer-to pendo-settings-transfertomodal-transferto"
              }
            />
            {renderErr("transferTo")}
          </FormControl>
        </ModalBody>
        <ModalFooter mt={2} justifyContent={"flex-start"}>
          <Button
            variant={"purple"}
            onClick={handleSubmit(onSubmit)}
            className={
              "pendo-transfer-to-modal-save pendo-settings-transfertomodal-savebtn"
            }
          >
            Save
          </Button>
          <Button
            variant={"text"}
            onClick={onClose}
            className={
              "pendo-transfer-to-modal-cancel pendo-settings-transfertomodal-cancelbtn"
            }
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TransferToModal;
