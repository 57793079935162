import {
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Text,
  Stack,
  Divider,
  Collapse,
  useOutsideClick,
  Button,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { GoCheck } from "react-icons/go";
import { ReactComponent as NoTemplates } from "src/assets/NoTemplates.svg";
import { useEffect, useMemo, useRef, useState } from "react";
import { MdAccessTime, MdExpandLess, MdExpandMore } from "react-icons/md";
import { CallStatusTemplateType } from "src/hooks/queries/settings/useContactCallStatusTemplates";
import { statusLabels as statusLabelOptions } from "src/components/shared/navigationMenu/Header/StatusMenu/index";
import useFormatDateInMyTimeZone from "src/hooks/useFormatDateInMyTimeZone";
import dayjs from "dayjs";
import { BiCalendar } from "react-icons/bi";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface IProps {
  statusLabels: any;
  status: string;
  statusInternal: string;
  setStatusInternal: any;
  statusStartSelect: string;
  setStatusStartSelect: React.Dispatch<React.SetStateAction<string>>;
  statusExpireSelect: string;
  setStatusExpireSelect: any;
  statusExpireAtUTC: string | null;
  setStatusExpireAtUTC: (payload: string | null) => void;
  expiresAtForLabel: string | null;
  customStart: string;
  setCustomStart: React.Dispatch<React.SetStateAction<string>>;
  customExpire: any;
  setCustomExpire: any;
  isTransferToProvided: boolean;
  transferToInternal: any;
  setTransferToInternal: any;
  templates: any;
  isTemplatesLoading: boolean;
  callStatusTemplateId: null | string;
  templateIdInternal: null | string;
  setTemplateIdInternal: any;
  errors: any;
  setErrors: any;
  isLoading: any;
}

export const renderTemplateInfo = (template?: CallStatusTemplateType) => {
  if (!template) return null;
  const lines = [];
  let text = "";
  if (template.takeCalls === "false") {
    if (
      template.unavailabilityExplanation === "Other" &&
      template.unavailabilityExplanationOther
    ) {
      text += `${template.unavailabilityExplanationOther} - `;
    }
    if (
      template.unavailabilityExplanation &&
      template.unavailabilityExplanation !== "Other" &&
      template.unavailabilityExplanation !== "Unavailable"
    ) {
      text += `${template.unavailabilityExplanation} - `;
    }
  }

  text += template.takeCalls === "true" ? "Available" : "Unavailable";
  // text += ",";
  lines.push(text);

  if (template.takeCalls === "true") {
    if (template.doNotConnect) {
      lines.push(
        `Do not connect: ${template.doNotConnect.split(";").join(", ")}`
      );
    }
  } else {
    if (template.connectOnly) {
      lines.push(`Connect only: ${template.connectOnly.split(";").join(", ")}`);
    }
  }

  if (template.statusNote) {
    lines.push(`Note: ${template.statusNote}`);
  }

  return (
    <Flex flexDirection={"column"} flexGrow={1} maxWidth={"250px"} py={2}>
      <Flex alignItems={"center"}>
        <Flex
          height={"8px"}
          width={"8px"}
          backgroundColor={
            template.takeCalls === "true" ? "#2ABD1D" : "#E80B0B"
          }
          borderRadius={"4px"}
        />
        <Flex
          ml={2}
          fontWeight={400}
          fontSize={"14px"}
          lineHeight={"18px"}
          color={"#4F4F4F"}
        >
          {template.name}
        </Flex>
      </Flex>
      <Flex
        fontWeight={400}
        fontSize={"12px"}
        lineHeight={"14px"}
        color={"#666"}
      >
        <Stack spacing=".5">
          {lines.map((line) => (
            <Text key={line}>{line}</Text>
          ))}
        </Stack>
      </Flex>
    </Flex>
  );
};

// similar to currentTemplateInfo, I just need to export it
export const renderCurrentTemplateInfo = (
  templateIdInternal: string,
  templates: CallStatusTemplateType[]
) => {
  if (templateIdInternal && templates?.length) {
    const currentTemplate = templates?.find(
      (t: any) => t.id === templateIdInternal
    );
    return renderTemplateInfo(currentTemplate!);
  }
  return null;
};
const TEMPLATES_SHOWN_INCREMENT = 3;

const StatusMenuContextGeneral = ({
  statusLabels,
  status,
  statusInternal,
  setStatusInternal,
  statusStartSelect,
  setStatusStartSelect,
  statusExpireSelect,
  setStatusExpireSelect,
  statusExpireAtUTC,
  setStatusExpireAtUTC,
  expiresAtForLabel,
  customStart,
  setCustomStart,
  customExpire,
  setCustomExpire,
  isTransferToProvided,
  transferToInternal,
  setTransferToInternal,
  templates,
  isTemplatesLoading,
  callStatusTemplateId,
  templateIdInternal,
  setTemplateIdInternal,
  errors,
  setErrors,
  isLoading,
}: IProps) => {
  const {
    getMyTimeZoneAbbrString,
    formatDateObjInMyTimeZoneForInput,
    moveDateToMyTimeZone,
  } = useFormatDateInMyTimeZone();
  const [isTemplatesMenuOpen, setIsTemplatesMenuOpen] = useState(false);
  const [queryFilter, setQueryFilter] = useState("");
  const [templatesShownCount, setTemplatesShownCount] = useState(
    TEMPLATES_SHOWN_INCREMENT
  );

  const templatesContainerRef = useRef(null);

  useOutsideClick({
    ref: templatesContainerRef,
    handler: () => {
      setIsTemplatesMenuOpen(false);
    },
  });

  useEffect(() => {
    if (!isTemplatesMenuOpen) {
      setQueryFilter("");
      setTemplatesShownCount(TEMPLATES_SHOWN_INCREMENT);
    }
  }, [isTemplatesMenuOpen]);

  useEffect(() => {
    if (queryFilter) {
      setTemplatesShownCount(TEMPLATES_SHOWN_INCREMENT);
    }
  }, [queryFilter]);

  const currentTemplateInfo = () => {
    if (templateIdInternal && templates?.data?.length) {
      const currentTemplate = templates?.data?.find(
        (t: any) => t.id === templateIdInternal
      );
      if (currentTemplate) {
        return renderTemplateInfo(currentTemplate);
      }
      return null;
    }
    return null;
  };

  const filteredTemplates = useMemo(() => {
    const temp =
      (queryFilter
        ? templates?.data?.filter((t: any) =>
            t.name.toLowerCase().includes(queryFilter.toLowerCase())
          )
        : templates?.data?.slice(0, templatesShownCount)) || [];

    temp.sort((a: any, b: any) => a.name.localeCompare(b.name));
    return temp;
  }, [templates?.data, queryFilter, templatesShownCount]);

  const onShowMore = () => {
    setTemplatesShownCount(templatesShownCount + TEMPLATES_SHOWN_INCREMENT);
  };

  const templatesShownLeft = useMemo(() => {
    if (templates?.data?.length && filteredTemplates?.length && !queryFilter) {
      return templates?.data?.length - filteredTemplates?.length;
    }
    return 0;
  }, [templates, filteredTemplates]);

  const renderStatusOptions = ({
    statValue,
    statLabel,
  }: {
    statValue: string;
    statLabel: string;
  }) => {
    if (
      status !== statusLabelOptions.EMERGENCY_CALLS_ONLY &&
      statLabel === statusLabelOptions.EMERGENCY_CALLS_ONLY
    ) {
      return null;
    }

    if (
      status === statusLabelOptions.EMERGENCY_CALLS_ONLY &&
      statLabel === statusLabelOptions.EMERGENCY_CALLS_ONLY
    ) {
      return (
        <Flex
          align="center"
          justifyContent={"space-between"}
          flexGrow={1}
          onClick={(event: any) => {
            event.stopPropagation();
            setStatusInternal(statusLabelOptions.EMERGENCY_CALLS_ONLY);
          }}
        >
          <Flex align="center">
            <Flex
              height={"8px"}
              width={"8px"}
              backgroundColor={"#ED8936"}
              borderRadius={"4px"}
            />
            <Flex ml={"15px"}>
              <Flex>
                <Text fontSize={14} fontWeight={600}>
                  {statLabel}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          {/* stat value is offline for emergency calls only */}
          <Flex align="center">
            {statusInternal === statusLabelOptions.EMERGENCY_CALLS_ONLY &&
              !isLoading && <Icon ml={6} as={GoCheck} fontSize="20px" />}
          </Flex>
        </Flex>
      );
    }

    return (
      <Flex
        align="center"
        justifyContent={"space-between"}
        flexGrow={1}
        onClick={(event: any) => {
          event.stopPropagation();
          setStatusInternal(statValue);
        }}
      >
        <Flex align="center">
          <Flex
            height={"8px"}
            width={"8px"}
            backgroundColor={
              statValue === "Online"
                ? "#2ABD1D"
                : statValue === "Offline"
                ? "#E80B0B"
                : "#FFF"
            }
            borderRadius={"4px"}
          />
          <Flex ml={"15px"}>
            <Flex>
              <Text fontSize={14} fontWeight={600}>
                {statLabel}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex align="center">
          {statValue === statusInternal && !isLoading ? (
            <Icon ml={6} as={GoCheck} fontSize="20px" />
          ) : null}
        </Flex>
      </Flex>
    );
  };

  const customStartDateFullVal = customStart
    ? formatDateObjInMyTimeZoneForInput(
        dayjs(customStart),
        "YYYY-MM-DD HH::mm"
      ) || ""
    : "";

  const customStartDateVal = customStart
    ? formatDateObjInMyTimeZoneForInput(dayjs(customStart), "MM/DD/YYYY") || ""
    : "";

  const customStartTimeVal = customStart
    ? formatDateObjInMyTimeZoneForInput(dayjs(customStart), "h:mm a") || ""
    : "";

  const customExpireFullVal = customExpire
    ? formatDateObjInMyTimeZoneForInput(
        dayjs(customExpire),
        "YYYY-MM-DD HH::mm"
      ) || ""
    : "";

  const customExpireDateVal = customExpire
    ? formatDateObjInMyTimeZoneForInput(dayjs(customExpire), "MM/DD/YYYY") || ""
    : "";

  const customExpireTimeVal = customExpire
    ? formatDateObjInMyTimeZoneForInput(dayjs(customExpire), "h:mm a") || ""
    : "";

  const getMinDateTime = () => {
    const now = dayjs();
    const fiveMinutesLater = now.add(5, "minute");
    const minDate = formatDateObjInMyTimeZoneForInput(fiveMinutesLater);
    return minDate ? new Date(minDate) : undefined;
  };

  const getMaxDateTime = () => {
    const now = dayjs();
    const oneMonthLater = now.add(1, "month");
    const maxDate = formatDateObjInMyTimeZoneForInput(oneMonthLater);
    return maxDate ? new Date(maxDate) : undefined;
  };

  return (
    <>
      <Flex
        mt={5}
        direction={"column"}
        padding={"10px 10px"}
        background={"#F2F2F2"}
        borderRadius={"5px"}
      >
        {Object.entries<string>(statusLabels).map(([statValue, statLabel]) => {
          const isNotECWhenEc =
            status !== statusLabelOptions.EMERGENCY_CALLS_ONLY &&
            statLabel === statusLabelOptions.EMERGENCY_CALLS_ONLY;

          if (isNotECWhenEc) return null;

          return (
            <Flex
              key={`menu-item-${statValue}`}
              py={"6px"}
              px={"10px"}
              borderRadius={"5px"}
              cursor={"pointer"}
              _hover={{
                background: "var(--chakra-colors-gray-200)",
              }}
              _focus={{
                background: "transparent",
              }}
            >
              {renderStatusOptions({ statValue, statLabel })}
            </Flex>
          );
        })}
      </Flex>

      {/* Templates */}

      {statusInternal === "Template" && (
        <Flex flexDirection={"column"} mt={"18px"} ref={templatesContainerRef}>
          <Flex flexGrow={1} flexDirection={"column"}>
            <FormControl>
              <FormLabel
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"19px"}
                color={"#4F4F4F"}
              >
                Template
              </FormLabel>
              <Flex
                mt={1}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
                minHeight={"40px"}
                background={"#E4ECFC"}
                border={"1px solid #CBD8F1"}
                borderRadius={"20px"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"19px"}
                pl={4}
                color={"#666"}
                cursor="default"
                onClick={() => {
                  setIsTemplatesMenuOpen(!isTemplatesMenuOpen);
                }}
              >
                <Flex>
                  {templateIdInternal
                    ? currentTemplateInfo()
                    : "Select Template"}
                </Flex>

                <Flex mr={"0.5rem"} style={{ fontSize: "1.2rem" }}>
                  {isTemplatesMenuOpen ? <MdExpandLess /> : <MdExpandMore />}
                </Flex>
              </Flex>
              {errors?.templateIdInternal && (
                <FormHelperText
                  color={"#E80B0B"}
                  fontSize={"12px"}
                  whiteSpace={"break-spaces"}
                >
                  {errors.templateIdInternal}
                </FormHelperText>
              )}
            </FormControl>
          </Flex>
          <Collapse in={isTemplatesMenuOpen}>
            <Flex
              flexDirection={"column"}
              boxShadow={"0px 0px 10px rgba(0, 0, 0, 0.15)"}
              borderRadius={"5px"}
              py={4}
              m={1}
            >
              <Flex mb={2} px={3}>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <Icon as={FiSearch} color="#A5B5E3" fontSize="20px" />
                  </InputLeftElement>
                  <Input
                    backgroundColor="#fff"
                    placeholder="Search Template"
                    borderRadius="40px"
                    value={queryFilter}
                    onChange={(event: any) => {
                      setQueryFilter(event.target.value);
                    }}
                  />
                </InputGroup>
              </Flex>
              {!filteredTemplates.length && !isTemplatesLoading && (
                <Flex
                  flexDirection={"column"}
                  flexGrow={1}
                  alignItems={"center"}
                  px={3}
                >
                  <NoTemplates />
                  <Text
                    fontWeight={400}
                    fontSize={"12px"}
                    lineHeight={"16px"}
                    color={"#BDBDBD"}
                  >
                    {!filteredTemplates.length
                      ? "No templates found"
                      : "You haven’t saved any status templates. You can create one by selecting available or unavailable status, setting the details and save as template."}
                  </Text>
                </Flex>
              )}
              {filteredTemplates?.map((template: any, iTemplate: number) => (
                <>
                  {iTemplate !== 0 && <Divider />}
                  <Flex
                    key={Date.now()}
                    onClick={(event: any) => {
                      event.stopPropagation();
                      setTemplateIdInternal(template.id);
                      setIsTemplatesMenuOpen(false);
                      const newErrors = { ...errors };
                      delete newErrors["templateIdInternal"];
                      setErrors(newErrors);
                    }}
                    px={3}
                    _hover={{
                      background: "var(--chakra-colors-gray-200)",
                    }}
                    cursor={"pointer"}
                  >
                    {renderTemplateInfo(template)}
                  </Flex>
                </>
              ))}
              {!!templatesShownLeft && (
                <Flex mt={2} justifyContent={"center"}>
                  <Button variant="link" onClick={onShowMore}>
                    {`Show More ( ${templatesShownLeft} Left )`}
                  </Button>
                </Flex>
              )}
            </Flex>
          </Collapse>
        </Flex>
      )}

      {/* Expiration */}
      <Flex
        mt={4}
        direction={"column"}
        align="center"
        justifyContent={"space-between"}
        flexGrow={1}
        onClick={(event: any) => event.stopPropagation()}
      >
        <Flex width={"100%"} mt={4}>
          <FormControl>
            <FormLabel
              fontWeight={400}
              fontSize={"sm"}
              lineHeight={"19px"}
              color={"#4F4F4F"}
            >
              When
            </FormLabel>
            <Select
              mt={2}
              value={statusStartSelect}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                const value = event.target.value;
                setStatusStartSelect(value);
                if (!value) {
                  setCustomStart("");
                }
                if ("later" === value) {
                  setStatusExpireSelect("custom");
                }
                setErrors({});
              }}
              height={"40px"}
              background={"#E4ECFC"}
              border={"1px solid #CBD8F1"}
              borderRadius={"40px"}
              placeholder={"Now"}
              fontWeight={400}
              fontSize={"14px"}
              lineHeight={"19px"}
              color={"#666"}
            >
              <option value={"later"}>Later</option>
            </Select>
          </FormControl>
        </Flex>
        {statusStartSelect === "later" && (
          <Flex width={"100%"} mt={4}>
            <FormControl>
              <SimpleGrid columns={2} spacingY={0} spacingX={1}>
                <FormLabel
                  fontWeight={400}
                  fontSize={"12px"}
                  lineHeight={"14px"}
                  color={"#B5B5B5"}
                  mb={"5px"}
                >
                  Start Date
                </FormLabel>
                <FormLabel
                  fontWeight={400}
                  fontSize={"12px"}
                  lineHeight={"14px"}
                  color={"#B5B5B5"}
                  mb={"5px"}
                >
                  Start Time
                </FormLabel>
                <ReactDatePicker
                  id={"dp_startdate"}
                  enableTabLoop={false}
                  selected={
                    customStartDateVal ? new Date(customStartDateVal) : null
                  }
                  onChange={(newDate: any) => {
                    const newDateObj = dayjs(newDate);
                    const startTimeObj = customStartDateFullVal
                      ? moveDateToMyTimeZone(customStartDateFullVal) || dayjs()
                      : dayjs();
                    const mergedDate = newDateObj
                      .hour(startTimeObj.hour())
                      .minute(startTimeObj.minute())
                      .second(startTimeObj.second())
                      .millisecond(startTimeObj.millisecond());

                    const dateVal = moveDateToMyTimeZone(
                      mergedDate.format("YYYY-MM-DD HH:mm")
                    );
                    setCustomStart(dateVal?.toString() || "");
                    setErrors({});
                  }}
                  ariaLabelledBy={"Custom Start Date"}
                  customInput={
                    <Flex
                      direction={"row"}
                      alignItems={"center"}
                      gap={1}
                      border={"1px solid #e2e8f0"}
                      borderRadius={"3xl"}
                      backgroundColor={"#e4ecfc"}
                      py={1}
                      pl={3}
                      pr={0}
                      fontSize={"sm"}
                      h={10}
                    >
                      <Box>{customStartDateVal}</Box>
                      <Button
                        variant={"ghost"}
                        className={"custom-datepicker-icon"}
                        px={0}
                        ml={"auto"}
                        mr={1}
                        w={5}
                        minW={5}
                        maxW={5}
                      >
                        <BiCalendar />
                      </Button>
                    </Flex>
                  }
                  minDate={getMinDateTime()}
                  maxDate={getMaxDateTime()}
                  className={"pendo-settings-callstatus-customstart-startdate"}
                />
                <ReactDatePicker
                  id={"dp_starttime"}
                  enableTabLoop={false}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  dateFormat={"h:mm a"}
                  selected={
                    customStartDateFullVal
                      ? new Date(customStartDateFullVal)
                      : null
                  }
                  onChange={(newDate: any) => {
                    const dateVal =
                      moveDateToMyTimeZone(newDate)?.toDate() || null;
                    setCustomStart(dateVal?.toString() || "");
                    setErrors({});
                  }}
                  ariaLabelledBy={"Custom Start Time"}
                  customInput={
                    <Flex
                      direction={"row"}
                      alignItems={"center"}
                      gap={1}
                      border={"1px solid #e2e8f0"}
                      borderRadius={"3xl"}
                      backgroundColor={"#e4ecfc"}
                      py={1}
                      pl={2}
                      pr={0}
                      fontSize={"sm"}
                      h={10}
                    >
                      <Box>{customStartTimeVal}</Box>
                      <Box
                        flex={1}
                        whiteSpace={"nowrap"}
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                      >
                        {customStartDateFullVal
                          ? getMyTimeZoneAbbrString(
                              dayjs(customStartDateFullVal)
                            )
                          : ""}
                      </Box>
                      <Button
                        variant={"ghost"}
                        className={"custom-datepicker-icon"}
                        px={0}
                        w={5}
                        minW={5}
                        maxW={5}
                        mr={1}
                      >
                        <MdAccessTime />
                      </Button>
                    </Flex>
                  }
                  className={"pendo-settings-callstatus-customstart-starttime"}
                />
              </SimpleGrid>
              {errors?.customStart && (
                <FormHelperText
                  color={"#E80B0B"}
                  fontSize={"xs"}
                  whiteSpace={"break-spaces"}
                >
                  {errors.customStart}
                </FormHelperText>
              )}
            </FormControl>
          </Flex>
        )}
        {statusStartSelect !== "later" && (
          <>
            <Flex width={"100%"} mt={4}>
              <FormControl>
                <FormLabel
                  fontWeight={400}
                  fontSize={"14px"}
                  lineHeight={"19px"}
                  color={"#4F4F4F"}
                >
                  For how long?
                </FormLabel>
                <Select
                  mt={"5px"}
                  value={statusExpireSelect}
                  onChange={(event: any) => {
                    setStatusExpireSelect(event.target.value);
                    setErrors({});
                  }}
                  height={"40px"}
                  background={"#E4ECFC"}
                  border={"1px solid #CBD8F1"}
                  borderRadius={"40px"}
                  fontWeight={400}
                  fontSize={"14px"}
                  lineHeight={"19px"}
                  color={"#666"}
                >
                  {"later" !== statusStartSelect && (
                    <option value={""}>No Changes</option>
                  )}
                  {"later" !== statusStartSelect &&
                    [1, 2, 3, 4].map((hours: number) => (
                      <option key={`hours-option-${hours}`} value={hours}>
                        Next {hours} hour{hours > 1 && "s"}
                      </option>
                    ))}
                  <option value={"custom"}>Custom</option>
                </Select>
                {errors?.nextExpireAtUTC && statusExpireSelect !== "custom" && (
                  <FormHelperText
                    color={"#E80B0B"}
                    fontSize={"sm"}
                    whiteSpace={"break-spaces"}
                  >
                    {errors.nextExpireAtUTC}
                  </FormHelperText>
                )}
              </FormControl>
            </Flex>
            <Flex
              fontWeight={400}
              fontSize={"12px"}
              lineHeight={"16px"}
              color={"#B5B5B5"}
              mt={2}
              width={"100%"}
              fontStyle={"italic"}
            >
              {expiresAtForLabel
                ? `Expires at ${expiresAtForLabel}`
                : "Does not expire"}
            </Flex>
          </>
        )}
        {statusExpireSelect === "custom" && (
          <Flex width={"100%"} mt={4}>
            <FormControl>
              <SimpleGrid columns={2} spacingY={0} spacingX={1}>
                <FormLabel
                  fontWeight={400}
                  fontSize={"xs"}
                  lineHeight={"14px"}
                  color={"#B5B5B5"}
                  mb={"5px"}
                >
                  {"later" === statusStartSelect
                    ? "End Date"
                    : "Custom Expire Date"}
                </FormLabel>
                <FormLabel
                  fontWeight={400}
                  fontSize={"xs"}
                  lineHeight={"14px"}
                  color={"#B5B5B5"}
                  mb={"5px"}
                >
                  {"later" === statusStartSelect
                    ? "End Time"
                    : "Custom Expire Time"}
                </FormLabel>
                <ReactDatePicker
                  id={"dp_customexpiredate"}
                  enableTabLoop={false}
                  selected={
                    customExpireDateVal ? new Date(customExpireDateVal) : null
                  }
                  onChange={(newDate: any) => {
                    const newDateObj = dayjs(newDate);
                    const customExpireObj = customExpireFullVal
                      ? moveDateToMyTimeZone(customExpireFullVal) || dayjs()
                      : dayjs();
                    const mergedDate = newDateObj
                      .hour(customExpireObj.hour())
                      .minute(customExpireObj.minute())
                      .second(customExpireObj.second())
                      .millisecond(customExpireObj.millisecond());

                    const dateVal = moveDateToMyTimeZone(
                      mergedDate.format("YYYY-MM-DD HH:mm")
                    );
                    setCustomExpire(dateVal?.toString() || "");
                    setErrors({});
                  }}
                  ariaLabelledBy={"Custom Expire Date"}
                  customInput={
                    <Flex
                      direction={"row"}
                      alignItems={"center"}
                      gap={1}
                      border={"1px solid #e2e8f0"}
                      borderRadius={"3xl"}
                      backgroundColor={"#e4ecfc"}
                      py={1}
                      pl={3}
                      pr={0}
                      fontSize={"sm"}
                      h={10}
                    >
                      <Box>{customExpireDateVal}</Box>
                      <Button
                        variant={"ghost"}
                        className={"custom-datepicker-icon"}
                        px={0}
                        ml={"auto"}
                        mr={1}
                        w={5}
                        minW={5}
                        maxW={5}
                      >
                        <BiCalendar />
                      </Button>
                    </Flex>
                  }
                  minDate={getMinDateTime()}
                  maxDate={getMaxDateTime()}
                  className={"pendo-settings-callstatus-customexpire-enddate"}
                />
                <ReactDatePicker
                  id={"dp_customexpiretime"}
                  enableTabLoop={false}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  dateFormat={"h:mm a"}
                  selected={
                    customExpireFullVal ? new Date(customExpireFullVal) : null
                  }
                  onChange={(newDate: any) => {
                    const dateVal =
                      moveDateToMyTimeZone(newDate)?.toDate() || null;
                    setCustomExpire(dateVal?.toString() || "");
                    setErrors({});
                  }}
                  ariaLabelledBy={"Custom Expire Time"}
                  customInput={
                    <Flex
                      direction={"row"}
                      alignItems={"center"}
                      gap={1}
                      border={"1px solid #e2e8f0"}
                      borderRadius={"3xl"}
                      backgroundColor={"#e4ecfc"}
                      py={1}
                      pl={2}
                      pr={0}
                      fontSize={"sm"}
                      h={10}
                    >
                      <Box>{customExpireTimeVal}</Box>
                      <Box
                        flex={1}
                        whiteSpace={"nowrap"}
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                      >
                        {customExpireFullVal
                          ? getMyTimeZoneAbbrString(dayjs(customExpireFullVal))
                          : ""}
                      </Box>
                      <Button
                        variant={"ghost"}
                        className={"custom-datepicker-icon"}
                        px={0}
                        w={5}
                        minW={5}
                        maxW={5}
                        mr={1}
                      >
                        <MdAccessTime />
                      </Button>
                    </Flex>
                  }
                  className={"pendo-settings-callstatus-customexpire-endtime"}
                />
              </SimpleGrid>
              {errors?.nextExpireAtUTC && (
                <FormHelperText
                  color={"#E80B0B"}
                  fontSize={"sm"}
                  whiteSpace={"break-spaces"}
                >
                  {errors.nextExpireAtUTC}
                </FormHelperText>
              )}
            </FormControl>
          </Flex>
        )}
        {!isTransferToProvided && statusInternal === "Online" && (
          <Flex mt={4} width={"100%"}>
            <FormControl>
              <FormLabel
                fontWeight={400}
                fontSize={"12px"}
                lineHeight={"14px"}
                color={"#B5B5B5"}
                mb={"5px"}
              >
                Transfer to?
              </FormLabel>
              <InputGroup>
                <InputLeftElement
                  height={"25px"}
                  fontSize={"12px"}
                  width={"25px"}
                >
                  +
                </InputLeftElement>
                <Input
                  value={transferToInternal}
                  onChange={(event: any) => {
                    setTransferToInternal(event.target.value);
                    const newErrors = { ...errors };
                    delete newErrors["transferToInternal"];
                    setErrors(newErrors);
                  }}
                  type="tel"
                  height={"25px"}
                  background={"#E4ECFC"}
                  border={"1px solid #CBD8F1"}
                  borderRadius={"40px"}
                  fontSize={"12px"}
                  lineHeight={"14px"}
                  color={"#666666"}
                  pl={"25px"}
                  placeholder={"Enter Phone Number"}
                />
              </InputGroup>
              {errors?.transferToInternal && (
                <FormHelperText
                  color={"#E80B0B"}
                  fontSize={"12px"}
                  whiteSpace={"break-spaces"}
                >
                  {errors?.transferToInternal}
                </FormHelperText>
              )}
            </FormControl>
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default StatusMenuContextGeneral;
