import { useToast } from "@chakra-ui/react";
import { useCompanySelection } from "src/store/companySelectionState";
import { useMutation } from "react-query";
import axios from "src/services/network/axios";
import { AxiosError } from "axios";
import { YesOrNoType } from "src/hooks/queries/account/useCallStatusSchedulersQuery";
import getCallStatusSchedulersKeys from "src/hooks/queries/account/useCallStatusSchedulersQuery/getCallStatusSchedulersKeys";
import { useQueryClient } from "react-query";
import { TDateISO } from "src/types";

// SAMPLE PAYLOAD*
// const foo = {
//   changeStatusTo: "Yes",
//   applyStatusOn: "2023-02-01T15:58:00.000Z",
//   revertStatusOn: "2023-02-01T17:59:00.000Z",
//   doNotConnect: "+790111111111",
//   connectOnly: "+390111111111",
//   unavailabilityExplanation: "Unavailable",
//   unavailabilityExplanationOther: "One more",
//   statusNote: "And ofcourse status, how without it",
// };

// export type AddScheduledCallStatusType = {
//   changeStatusTo: YesOrNoType;
//   applyStatusOn: TDateISO | null; // date String
//   revertStatusOn: TDateISO | null; // date String
//   doNotConnect?: string | null;
//   connectOnly?: string | null;
//   unavailabilityExplanation?: string | null;
//   unavailabilityExplanationOther?: string | null;
//   statusNote?: string | null;
// };

export type AddScheduledCallStatusType = {
  changeStatusTo: YesOrNoType;
  applyStatusOn: TDateISO; // date String
  revertStatusOn: TDateISO; // date String
  doNotConnect?: string;
  connectOnly?: string;
  unavailabilityExplanation?: string;
  unavailabilityExplanationOther?: string;
  statusNote?: string;
};

export type AddNewScheduledCallStatusTemplateType = {
  callStatusTemplateId: string;
  revertStatusOn: string | null;
  applyStatusOn: string;
};

const addCallStatusSchedulers =
  (companyId: string, contactId: string) =>
  async (
    payload: AddScheduledCallStatusType | AddNewScheduledCallStatusTemplateType
  ) => {
    const body = payload;
    // "{{baseUrl}}/companies/:companyId/contacts/:contactId/callStatusSchedulers"
    const response = await axios.post(
      `/companies/${companyId}/contacts/${contactId}/callStatusSchedulers`,
      body
    );

    return response;
  };

type ParamType = {
  currContactId?: string;
  onSuccess?: (arg: any) => void;
};

const useAddCallStatusSchedulers = (options?: ParamType) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const companySelection = useCompanySelection();
  const companyId = companySelection!.activeCompany as string;
  const contactId =
    options?.currContactId ??
    (companySelection!.activeCompanyObject?.contactId as string);

  const queryKey = getCallStatusSchedulersKeys({
    companyId,
    contactId,
  });

  const mutation = useMutation(addCallStatusSchedulers(companyId, contactId), {
    onSuccess(response) {
      queryClient.invalidateQueries(queryKey);
      options?.onSuccess && options.onSuccess(response);
      toast({
        title: "Create Upcoming Status Success",
        description: "Upcoming status was created successfully!",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    },
    onError(err: AxiosError) {
      const errMessage = err?.response?.data?.message;
      const isStringErr = typeof errMessage === "string";
      const status = err?.response?.status;
      if (status === 409) return;
      toast({
        title: "Error",
        description: isStringErr
          ? errMessage
          : "Failed To Add Upcoming Status.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    },
  });

  return mutation;
};

export default useAddCallStatusSchedulers;
