import {
  Button,
  Flex,
  CloseButton,
  Checkbox,
  Text,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
} from "@chakra-ui/react";
import StatusMenuContextGeneral from "./StatusMenuContextGeneral";
import StatusMenuContextAdvanced from "./StatusMenuContextAdvanced";
import { useEffect, useState } from "react";
import CenterSpinner from "src/components/shared/CenterSpinner";

const MAX_TEMPLATE_LENGTH = 50;
type CharCountProps = {
  curr: string | number | undefined;
  max: string | number;
  [key: string]: any;
};
const CharCountLabel = (props: CharCountProps) => {
  const { curr, max, ...rest } = props;

  return (
    <Flex flexDir="row-reverse" fontSize="xs" color="gray.600" {...rest}>
      <Text>
        {curr}/{max}
      </Text>
    </Flex>
  );
};

interface IProps {
  onClose: any;
  isOpen: boolean;
  isOpenExternal: boolean;
  statusLabels: any;
  status: string;
  statusInternal: string;
  setStatusInternal: any;
  isLoading: any;
  statusStartSelect: string;
  setStatusStartSelect: React.Dispatch<React.SetStateAction<string>>;
  statusExpireSelect: string;
  setStatusExpireSelect: any;
  statusExpireAtUTC: string | null;
  setStatusExpireAtUTC: (payload: string | null) => void;
  expiresAtForLabel: string | null;
  customExpire: any;
  setCustomExpire: any;
  customStart: string;
  setCustomStart: React.Dispatch<React.SetStateAction<string>>;
  isTransferToProvided: boolean;
  transferToInternal: any;
  setTransferToInternal: any;
  templates: any;
  isTemplatesLoading: boolean;
  callStatusTemplateId: null | string;
  templateIdInternal: null | string;
  setTemplateIdInternal: any;
  isSaveAsTemplate: boolean;
  setIsSaveAsTemplate: any;
  createTemplateName: string;
  setCreateTemplateName: any;
  errors: any;
  setErrors: any;
  onSave: any;
  isSaveShown: boolean;
  //
  isOpenAdvance: boolean;
  onToggleAdvance: any;
  callers: string[];
  setCallers: any;
  reason: string;
  setReason: any;
  otherReason: string;
  setOtherReason: any;
  note: string;
  setNote: any;
}

const StatusMenuContext = ({
  onClose,
  isOpen,
  isOpenExternal,
  statusLabels,
  status,
  statusInternal,
  setStatusInternal,
  statusStartSelect,
  setStatusStartSelect,
  statusExpireSelect,
  setStatusExpireSelect,
  statusExpireAtUTC,
  setStatusExpireAtUTC,
  expiresAtForLabel,
  customStart,
  setCustomStart,
  customExpire,
  setCustomExpire,
  isTransferToProvided,
  transferToInternal,
  setTransferToInternal,
  templates,
  isTemplatesLoading,
  callStatusTemplateId,
  templateIdInternal,
  setTemplateIdInternal,
  isSaveAsTemplate,
  setIsSaveAsTemplate,
  createTemplateName,
  setCreateTemplateName,
  errors,
  setErrors,
  onSave,
  isLoading,
  isSaveShown,
  // Advanced
  isOpenAdvance,
  onToggleAdvance,
  callers,
  setCallers,
  reason,
  setReason,
  otherReason,
  setOtherReason,
  note,
  setNote,
}: IProps) => {
  const isEmergencyCallOnlySelected = statusInternal === "Emergency Calls Only";
  const [isDirty, setIsDirty] = useState(false);
  const [originalValues, setOriginalValues] = useState(
    JSON.stringify({
      statusInternal,
      statusStartSelect,
      statusExpireSelect,
      createTemplateName,
      callers,
      reason,
      otherReason,
      note,
    })
  );

  useEffect(() => {
    const dirty =
      originalValues !==
      JSON.stringify({
        statusInternal,
        statusStartSelect,
        statusExpireSelect,
        createTemplateName,
        callers,
        reason,
        otherReason,
        note,
      });
    setIsDirty(dirty);
  }, [
    statusInternal,
    statusStartSelect,
    statusExpireSelect,
    createTemplateName,
    callers,
    reason,
    otherReason,
    note,
  ]);

  useEffect(() => {
    if (isOpen) {
      setIsDirty(false);
      setOriginalValues(
        JSON.stringify({
          statusInternal,
          statusStartSelect,
          statusExpireSelect,
          createTemplateName,
          callers,
          reason,
          otherReason,
          note,
        })
      );
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isSaveAsTemplate) {
      setCreateTemplateName("");
    }
  }, [isSaveAsTemplate]);

  useEffect(() => {
    if (isOpen) {
      setErrors([]);
    }
  }, [isOpen]);

  if (isLoading) {
    return <CenterSpinner />;
  }

  return (
    <Flex
      flexDirection={"column"}
      padding={"16px 20px"}
      maxHeight={isOpenExternal ? "auto" : "calc(100vh - 80px)"}
      overflowY={"auto"}
      className={
        "pendo-edit-call-status-popover pendo-settings-editcallstatus-popover"
      }
    >
      {/* Context header */}
      <Flex justify={"space-between"} alignItems={"center"}>
        <Flex
          fontWeight={"700"}
          fontSize={"16px"}
          lineHeight={"20px"}
          color={"#000"}
        >
          EDIT CALL STATUS
        </Flex>
        <Flex
          alignItems={"center"}
          cursor={"pointer"}
          onClick={() => {
            onClose();
          }}
        >
          <CloseButton />
        </Flex>
      </Flex>
      {/* General status fields and expiration */}
      <StatusMenuContextGeneral
        statusLabels={statusLabels}
        status={status}
        statusInternal={statusInternal}
        setStatusInternal={setStatusInternal}
        isLoading={isLoading}
        statusStartSelect={statusStartSelect}
        setStatusStartSelect={setStatusStartSelect}
        statusExpireSelect={statusExpireSelect}
        setStatusExpireSelect={setStatusExpireSelect}
        statusExpireAtUTC={statusExpireAtUTC}
        setStatusExpireAtUTC={setStatusExpireAtUTC}
        expiresAtForLabel={expiresAtForLabel}
        customStart={customStart}
        setCustomStart={setCustomStart}
        customExpire={customExpire}
        setCustomExpire={setCustomExpire}
        isTransferToProvided={isTransferToProvided}
        transferToInternal={transferToInternal}
        setTransferToInternal={setTransferToInternal}
        templates={templates}
        isTemplatesLoading={isTemplatesLoading}
        callStatusTemplateId={callStatusTemplateId}
        templateIdInternal={templateIdInternal}
        setTemplateIdInternal={setTemplateIdInternal}
        errors={errors}
        setErrors={setErrors}
      />
      {/* Advanced Settings */}
      {statusInternal !== "Template" && (
        <Flex mt={"14px"}>
          <StatusMenuContextAdvanced
            isOpen={isOpen}
            isOpenAdvance={isOpenAdvance}
            onToggleAdvance={onToggleAdvance}
            status={status}
            statusInternal={statusInternal}
            // Advanced
            callers={callers}
            setCallers={setCallers}
            reason={reason}
            setReason={setReason}
            otherReason={otherReason}
            setOtherReason={setOtherReason}
            note={note}
            setNote={setNote}
            errors={errors}
          />
        </Flex>
      )}
      {/* Save as template */}
      {statusInternal !== "Template" &&
        (!!callers.join("").trim() ||
          !!note.trim() ||
          (statusInternal === "Offline" &&
            !!reason &&
            reason !== "Unavailable")) &&
        !isEmergencyCallOnlySelected && (
          <Flex mt={"22px"} flexDirection={"column"}>
            <Checkbox
              colorScheme="purple"
              onChange={(e: any) => {
                const { checked } = e.target;
                setIsSaveAsTemplate(checked);
              }}
              isChecked={Boolean(isSaveAsTemplate)}
            >
              <Text fontWeight={400} fontSize="14px">
                Save as Template
              </Text>
            </Checkbox>
            {isSaveAsTemplate && (
              <FormControl mt={"16px"}>
                <FormLabel
                  fontWeight={400}
                  fontSize={"14px"}
                  lineHeight={"19px"}
                  color={"#4F4F4F"}
                >
                  Template Name
                </FormLabel>
                <Input
                  backgroundColor="#E4ECFC"
                  placeholder="Template Name"
                  fontSize={"14px"}
                  borderRadius="40px"
                  value={createTemplateName}
                  onChange={(event: any) => {
                    const val = event.target.value;
                    if (val.length <= MAX_TEMPLATE_LENGTH) {
                      setCreateTemplateName(val);
                    }
                  }}
                />
                <CharCountLabel
                  curr={createTemplateName.length}
                  max={MAX_TEMPLATE_LENGTH}
                />
                {errors?.createTemplateName && (
                  <FormHelperText
                    color={"#E80B0B"}
                    fontSize={"12px"}
                    whiteSpace={"break-spaces"}
                  >
                    {errors.createTemplateName}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </Flex>
        )}
      {/* Save button */}
      {isSaveShown && (
        <Flex mt={"24px"} justifyContent={"flex-start"} width={"100%"}>
          <Button
            py={"5px"}
            variant={"purple"}
            isDisabled={!isDirty}
            isLoading={isLoading}
            onClick={async () => {
              if (await onSave()) {
                onClose();
              }
            }}
            className={
              "pendo-edit-call-status pendo-settings-editcallstatus-savebtn"
            }
          >
            Save
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default StatusMenuContext;
